import React from 'react';

export class Mouse extends React.Component {

  constructor(props) {
    super(props);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.state = { 
      x: 0, 
      y: 0,
      isInteractedAndIdle: false,
      isTouching: false, 
    }
  }

  move = ({ x, y }) => {
    this.setState({ x,  y,  isInteractedAndIdle: false });
  }

  handleMouseMove = (event) => {
    if (this.state.isTouching) return;
    this.move({
      x: event.clientX,
      y: event.clientY,
    });

    let timeout;
    (() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.setState({ isInteractedAndIdle: true })
      }, 100);
    })();
  }

  handleTouchStart = (e) => {
    this.setState({ isTouching: true });
    console.log(e.touches[0].clientX)
    this.move({
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    })
  }

  handleTouchEnd = (e) => {
    let timeout;
    (() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.setState({ isTouching: false });
      }, 100);
    })();
  }

  handleTouchMove = (e) => {
    if (e.changedTouches && e.changedTouches.length) {
      this.move({
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY,
      })
    }
  }


  render() {
    return (
      <div 
        style={{ ...this.props.style }} 
        onMouseMove={this.handleMouseMove}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
      >
        { this.props.children(this.state) }
      </div>
    );
  }
} 