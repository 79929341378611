import React from 'react'

import SEO from '../components/seo'
import { Sherbet } from '../components/sherbet';
import Behance from './../images/behance.svg';
import Linkedin from './../images/linkedin.svg';
import Twitter from './../images/twitter.svg';
import Medium from './../images/medium.svg';

const css = {
  a: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}
const SocialLinks = ({
  style = {},
}) => (
  <div style={{ display: 'flex', width: '240px', marginLeft:'auto', marginRight:'auto', marginBottom: '40', justifyContent:'space-around', ...style }}>
    <a target="_blank" href="https://www.linkedin.com/in/nathanielkessler/" style={css.a}><Linkedin /></a>
    <a target="_blank" href="https://medium.com/@nathanielkessler" style={css.a}><Medium /></a>
    <a target="_blank" href="https://www.behance.net/nathanielkessler" style={css.a}><Behance /></a>
    {/* <a target="_blank" href="https://twitter.com/nathanielkess" style={css.a}><Twitter /></a> */}
  </div>
)

const IndexPage = () => (
  <Sherbet>
    <SEO title='Home' />
    <SocialLinks style={{ marginBottom: '20' }} />
    <p>I'm a <b>designer</b>, turned <b>developer</b>. Contact me here.</p>
    <p><a href="mailto:hey@nathanielkessler.com">hey@nathanielkessler.com</a></p>
  </Sherbet>
);

export default IndexPage
