import React from 'react';
import { Mouse } from './mouse';
import posed from 'react-pose'

const css = {
  wrapper: {
    position: 'absolute',
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sherbet: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    clipPath: "url(#mask)",
    "-webkit-clip-path": "url(#mask)",
    border: '1px solid transparent',
    "box-sizing": 'border-box'
  }
}


const Circle = posed.circle({
  opening: { 
    r: 900,
    transition: { duration: 180000 }
  },
  openingFast: {
    r: 800,
    transition: { duration: 50000 }
  },
  closing: { 
    r: 0 ,
    transition: { duration: 300 }
  },
})


export const Sherbet = ({
  children, 
  style = {}
}) => {

  return (
    <Mouse style={{ ...css.wrapper, ...style}}>
      {({isInteractedAndIdle, isTouching, x, y}) => {

        const poseState = (isTouching)
          ? "openingFast"
          : (isInteractedAndIdle)
          ? "opening"
          : "closing"

        return (
          <>
            <div style={{ zIndex: 10, paddingLeft:'20px', paddingRight: '20px', position: 'relative', textAlign: 'center' }}>
              {children}
            </div>
            <svg style={{ ...css.sherbet }} className="sherbet" viewBox="0 0 1440 900" fill="none" preserveAspectRatio="none">
              <g clipPath="url(#clip0)">
                <path fill="#fff" d="M0 0H1440V900H0z" />
                <path
                  d="M-72 631.991C-72 356.61 300.667 249.922 487 231v400.991c0 191.544-228.129 273.877-342.194 291.101C72.537 940.8-72 907.372-72 631.991z"
                  fill="url(#paint0_linear)"
                />
                <path
                  d="M229.905 346.178C441.378 603.695 164.749 785.037 0 843.518V900h526.655c100.875-45.585 88.283-181.608 69.377-243.922-81.024-257.117-277.844-313.732-366.127-309.9z"
                  fill="#E65D68"
                />
                <path
                  d="M858 356.393C858 167.267 1252.67 93.995 1450 81v275.393c0 131.549-241.6 188.094-362.39 199.923C1011.07 568.478 858 545.52 858 356.393z"
                  fill="url(#paint1_linear)"
                />
                <g filter="url(#filter0_d)">
                  <path
                    d="M746.756 349.5C712.219 541.9 1010.8 792.667 1164.41 894h215.35C901.368 495 921.95 429.5 938.013 367 972.124 234.28 1280.7 173.5 1440 160.5V-11h-322.78c-109.1 40-335.927 168.1-370.464 360.5z"
                    fill="url(#paint2_linear)"
                  />
                </g>
                <g filter="url(#filter1_d)">
                  <path
                    d="M742.74 462.886C496.845 352.564 607.121 108.328 692.995 0H-5v470.491c150.63-122.861 411.309-59.927 522.057 52.728C692.995 702.188 767.176 816.431 783.059 906H1502V255.525c-150.63 115.088-513.364 317.683-759.26 207.361z"
                    fill="url(#paint3_linear)"
                  />
                </g>
                <g filter="url(#filter2_d)">
                  <path
                    d="M325.962 373.956C590.732 515.704 517.766 785.714 448.187 903H1007C892.017 621.269 623.728 515.449 463.779 409.619 286.227 292.14 110.887 188.54 141.871-3H-5v309.696c136.811-5.22 277.646 42.665 330.962 67.26z"
                    fill="url(#paint4_linear)"
                  />
                </g>
                <g filter="url(#filter3_d)">
                  <path
                    d="M564.687-20h667.343C698.565 236.313 825.317 448.702 955.377 522.858 1044.35 571.569 1269.04 636.25 1456 505.281V901h-317.56c-60.68-103.951-256.694-278.329-417.784-395.719C413.308 281.309 463.946 101.193 564.687-20z"
                    fill="url(#paint5_linear)"
                  />
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d"
                  x={716}
                  y={-61}
                  width={796}
                  height={1005}
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx={22} />
                  <feGaussianBlur stdDeviation={25} />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                  id="filter1_d"
                  x={-95}
                  y={-36}
                  width={1597}
                  height={986}
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx={-50} dy={4} />
                  <feGaussianBlur stdDeviation={20} />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                  id="filter2_d"
                  x={-77}
                  y={-53}
                  width={1112}
                  height={1006}
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx={-22} />
                  <feGaussianBlur stdDeviation={25} />
                  <feColorMatrix values="0 0 0 0 0.616667 0 0 0 0 0.407 0 0 0 0 0 0 0 0 0.65 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <filter
                  id="filter3_d"
                  x={454}
                  y={-71}
                  width={1079}
                  height={1031}
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity={0} result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dx={22} dy={4} />
                  <feGaussianBlur stdDeviation={27.5} />
                  <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                  <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
                <linearGradient
                  id="paint0_linear"
                  x1={61.8803}
                  y1={230.272}
                  x2={440.542}
                  y2={230.272}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#67C6CF" />
                  <stop offset={1} stopColor="#67C6CF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                  id="paint1_linear"
                  x1={999.784}
                  y1={80.5002}
                  x2={1400.8}
                  y2={80.5002}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#67C6CF" />
                  <stop offset={1} stopColor="#67C6CF" stopOpacity={0.36} />
                </linearGradient>
                <linearGradient
                  id="paint2_linear"
                  x1={1092}
                  y1={-11}
                  x2={1493.52}
                  y2={251.957}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#4878C1" />
                  <stop offset={0.463542} stopColor="#22477E" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear"
                  x1={748.5}
                  y1={0}
                  x2={748.5}
                  y2={906}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F7EFC9" />
                  <stop offset={1} stopColor="#FBE468" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear"
                  x1={501}
                  y1={-3}
                  x2={501}
                  y2={903}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FDC58A" />
                  <stop offset={1} stopColor="#F4A24A" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear"
                  x1={971.5}
                  y1={-20}
                  x2={1441.63}
                  y2={1028.47}
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFAFE3" />
                  <stop offset={0.877215} stopColor="#CF3599" />
                </linearGradient>
                <clipPath id="clip0">
                  <path fill="#fff" d="M0 0H1440V900H0z" />
                </clipPath>
              </defs>
            </svg>
            <svg height="0" width="0">
              <clipPath id="mask">
                <Circle pose={poseState} r="0" cx={x} cy={y} />
              </clipPath>
            </svg>
          </>
        )
      }}
    </Mouse>
  )
}

